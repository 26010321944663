import React, { Component } from 'react';
import romain from '../assets/romain4.jpg';
import '../css/App.css';

export default class Publish extends Component {
  render() {
    return (
      <div className={"text-box"}>

        <h1>Ouvrages</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li><i>Logement social et politiques de non-discrimination en Europe</i>, L’harmattan, collection Questions contemporaines, Paris, février 2008, 221 pp.</li>
            <li><i>Le logement social : étude comparée de l’intervention publique en France et en Europe occidentale</i>, Librairie générale de droit et de jurisprudence, collection Bibliothèque de droit public, Tome 247, Paris, novembre 2006, 626 pp.</li>
          </ul>
        </div>

        <h1>Rapports Publics</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li><i>Logement social et politiques de non-discrimination en Europe</i>, Rapport public sous l’égide de l’agence nationale pour la cohésion sociale et l'égalité des chances (ACSE), 2007, 175 pp.</li>
          </ul>
        </div>

        <h1>Presse</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li>« Suicide du professeur Barrat : L'université de la Sorbonne Paris Nord reconnaît sa responsabilité », <i>20 minutes, 25 septembre 2023</i></li>
            <li>« Suicide du professeur Barrat : la Sorbonne Paris Nord reconnaît que le drame est « imputable au service », <i>Le Parisien, 23 septembre 2023</i></li>
            <li>« Suicide du professeur Barrat : la justice reconnait un accident de service », <i>Medscape, 7 août 2023</i></li>
            <li>« AP-HP : le suicide d’un professeur de médecine est reconnu comme un accident de service », <i>Médiapart, 26 juillet 2023</i></li>
            <li>« Suicide du professeur Barrat : l’université Sorbonne Paris Nord devra reconnaître « l’accident de service » », <i>Le Parisien, 24 juillet 2023</i></li>
            <li>« Suicide d’un chirurgien à l’hôpital de Bobigny : « C’est le travail qui l’a tué », estime sa famille », <i>Le Parisien, 11 novembre 2020</i></li>
            <li>« HLM : interventions à tous les étages », <i>Marianne, 22 au 28 septembre 2007</i></li>
            <li>« Le logement social est-il de gauche ? », <i>Le Parisien, 8 septembre 2007</i></li>
            <li>« Logement social, les trois modèles européens », <i>Le Monde, 23 février 2007</i></li>
            <li>« Trois questions à… Romain Graëffly », <i>La voix du Nord, 11 janvier 2007</i></li>
          </ul>
        </div>

        <div>
          <img src={romain} className={"photo"} alt={"romain_graeffly"} />
        </div>

        <h1>Articles</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li>« Réflexions sur les évolutions du logement social en Europe », <i>Bulletin d’information de la Société du logement de la Région bruxelloise</i>, n° 58, juin 2009, pp. 15-21</li>
            <li>« La mise en œuvre du droit au logement opposable : premier bilan », <i>Revue de Droit Sanitaire et Social</i>, n° 4/2009, juillet-août 2009, pp. 741-751</li>
            <li>« Le gardien d’HLM à travers la jurisprudence administrative : de l’arrêt Lauthier à nos jours », <i>Actualité juridique fonctions publiques</i>, n° 3, mai-juin 2009, pp. 161-167</li>
            <li>« L’office public de l’habitat : un nouveau statut pour des nouvelles politiques publiques de l’habitat », <i>Le conseiller général</i>, n° 26, février-mars 2009, pp. 129-136</li>
            <li>« Concession publique d’aménagement : le déclin progressif de la conception française », <i>Le conseiller général</i>, n° 25, décembre 2008-janvier 2009, pp. 69-72</li>
            <li>« L’histoire du logement social en Finlande : du droit au logement aux aides ARAVA »,<i> Le conseiller général</i>, n° 22, avril-mai 2008, pp. 153-160</li>
            <li>« Le logement en Europe : les politiques anti-discriminatoires », <i>Etudes foncières</i>, n° 132, mars-avril 2008, pp. 7-11</li>
            <li>« L’histoire du logement social en Allemagne », <i>Le conseiller général</i>, n° 19, septembre-octobre 2007, pp. 125-134</li>
            <li>« La réforme du logement municipal britannique (1975-2007) », <i>Etudes foncières</i>, n° 129, septembre-octobre 2007, pp. 14-19</li>
            <li>« Aspects administratifs de la loi n° 2007-290 du 5 mars 2007 instituant le droit au logement opposable et portant diverses mesures en faveur de la cohésion sociale », <i>Droit administratif</i>, juillet 2007, n° 7, pp. 11-15</li>
            <li>« L’histoire du logement social en Belgique », <i>Le conseiller général</i>, n° 17, mars-avril 2007, pp. 121-130</li>
            <li>« Les modes de gestion du logement social en Europe », <i>Le conseiller général</i>, n° 16, janvier-février 2007, pp. 165-170</li>
            <li>« Qualifications du logement social adoptées par les pays européens », <i>Le conseiller général</i>, n° 15, décembre 2006, pp. 103-108</li>
            <li>« Les implications du droit au logement », <i>Le conseiller général</i>, n° 14, septembre 2006, pp. 78-80</li>
            <li>« Les politiques sociales du logement en Norvège », <i>Etudes foncières</i>, n° 122, juillet-août 2006, pp. 24-27</li>
            <li>« Logement social et Etats-providence européens », <i>Le conseiller général</i>, n° 13, juin 2006, pp. 34-38</li>
            <li>« Panorama d’une prérogative foncière locale : <i>le droit de l’expropriation dans l’Union européenne », Etudes foncières</i>, n° 121, mai-juin 2006, pp. 24-29</li>
            <li>« Le logement social en Europe occidentale », <i>Bulletin d’information de la Société du logement de la Région bruxelloise</i>, n° 46, avril-mai 2006, pp. 8-18</li>
            <li>« Le Conseil constitutionnel algérien : de la greffe institutionnelle à l’avènement d’un contentieux constitutionnel ? », <i>Revue du droit public</i>, n° 5-2005, pp. 1381-1404</li>
            <li>« Vers une unification des politiques publiques de lutte contre les discriminations », <i>Actualité juridique droit administratif</i>, n° 17, 2 mai 2005, pp. 934-941</li>
          </ul>
        </div>

        <h1>Notes d'arrêts</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li>Ccass., 3e civ., 3 mai 2007, « Marraud des Grottes (M.) c/ Préfet de région Martinique », <i>Actualité juridique droit immobilier</i>, n°1, janvier 2008, pp. 48-50</li>
            <li>CE, 23 juin 2006, « Société Actilor », <i>Actualité juridique droit administratif</i>, n° 36, 30 octobre 2006, pp. 2026-2029</li>
            <li>Ccass., 3e civ., 13 juillet 2005, « Chatonville (sté) c/ de Cuverville », <i>Actualité juridique droit immobilier</i>, n° 11, 10 novembre 2005, pp. 847-848</li>
            <li>Ccass., 3e civ., 13 juillet 2005, « Ets Réseau ferré de France c/ Sté Laser propreté », <i>Actualité juridique droit immobilier</i>, n° 10, 10 octobre 2005, pp. 758-760</li>
            <li>Ccass., 3e civ., 12 juillet 2005, « Chapard (Mme) c/ Hazane (Mme) et autres », <i>Actualité juridique droit immobilier</i>, n° 10, 10 octobre 2005, pp. 750-751</li>
          </ul>
        </div>

      </div>
    );
  }
}
