import React, { Component } from 'react';
import romain from '../assets/romain1.jpg';
import '../css/App.css';

export default class Skill extends Component {
  render() {
    return (
      <div className={"text-box"}>
        <h1>Principales matières pratiquées</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li>Droit civil</li>
            <li>Droit administratif</li>
            <li>Droit commercial </li>
            <li>Droit pénal</li>
          </ul>
        </div>
        <div>
          <img src={romain} className={"photo"} alt="romain_graeffly" />
        </div>
        <h1>Formation</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li>2006-2007 : <b>Avocat diplômé de l’Ecole de Formation professionnelle des Barreaux du ressort de la Cour d’appel de Paris (EFB)</b></li>
            <li>2005 : <b>Prix de thèse de l’Université Panthéon-Assas (Paris II)</b></li>
            <li>2004 : <b>Docteur en Droit mention Droit public</b>, Université Panthéon-Assas (Paris II). Réalisation d’une thèse intitulée : <i>« Le logement social en France et en Europe occidentale : étude comparative de l’intervention publique »</i></li>
            <li>1999 : <b>Major du DEA de Sciences Administratives</b>, Université Panthéon-Assas (Paris II). Réalisation d’un mémoire intitulé <i>« Les relations entre les Offices HLM et leurs usagers »</i></li>
          </ul>
        </div>
      </div>
    );
  }
}
