import React, { Component } from 'react';
import plaque from '../assets/plaque.jpg';
import '../css/App.css';

export default class Plaque extends Component {
  render() {
    return (
      <div className={"Plaque-box"}>
        <img src={plaque} className={"Plaque-img"} alt="plaque" />
      </div>
    );
  }
}
