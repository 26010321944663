import React, { Component } from 'react';
import { slide as Menu } from "react-burger-menu";
import { Link } from 'react-router-dom'

import "./css/menu.css";

export default class sidebar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
    this.handleStateChange = this.handleStateChange.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.myRef = React.createRef();
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  render() {
    return (
      <Menu ref={this.myRef} isOpen={this.state.menuOpen} onStateChange={state => this.handleStateChange(state)} >
        <Link to={"/"} onClick={() => this.closeMenu()} >
          Présentation
        </Link>
        <Link to={"/competences"} onClick={() => this.closeMenu()} >
          Compétences
        </Link>
        <Link to={"/publications"} onClick={() => this.closeMenu()} >
          Publications
        </Link>
        <Link to={"/honoraires"} onClick={() => this.closeMenu()} >
          Honoraires
        </Link>
        <Link to={"/contact"} onClick={() => this.closeMenu()} >
          Contact
        </Link>
      </Menu>
    );
  }
};
