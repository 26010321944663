import React, { Component } from 'react';
import romain from '../assets/romain5.jpg';
import '../css/App.css';

export default class Contact extends Component {

  render() {
    return (
      <div className={"text-box"}>
        <h1>Un avocat parisien</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li>Le Cabinet GRAËFFLY est situé au rez-de-chaussée du 23, rue Bénard, dans le quatorzième arrondissement de PARIS, non loin de la porte d’Orléans et de la Gare Montparnasse.</li>
            <li>La rue Bénard donne dans la rue Pernety, qui est desservie par la ligne de Métro 13, à la station Pernety.</li>
            <li>La rue Bénard est également proche de la station de Métro Alésia, sur la ligne 4.</li>
          </ul>
        </div>
        <div>
          <img src={romain} className={"photo"} alt="romain_graeffly" />
        </div>
        <div class={"text-paragraph-center"}>
          <ul class={"no-style"}>
            <li>
              <span class={"break"}><b class={"special"}>Maître Romain Graëffly</b></span>
              <span class={"break"}>23, rue Bénard</span>
              <span class={"break"}>75014 PARIS</span>
            </li>
            <li><span class={"sub-data"}><span>03 86 25 </span><span>16 60</span><i> : .leT</i></span></li>
            <li><i>Mél : </i><span class={"sub-data"}><span>moc.</span><span>ylffearg@</span><span>niamor</span></span></li>
            <li><b>Uniquement sur rendez-vous</b></li>
          </ul>
        </div>
        <div class={"text-paragraph-center"}>
          <ul class={"no-style"}>
            <li><i>Administrateur du site : </i><span class={"sub-data"}><span>moc.</span><span>ylffearg</span>@retsambew</span></li>
          </ul>
        </div>
      </div>
    );
  }
}
