import React, { Component } from 'react';
import '../css/App.css';

export default class Footer extends Component {
  render() {
    return (
      <div className={"Footer-box"}>
        <span class={"sub-data"}><span>03 86 </span><span>25 16</span> 60 : tcatnoC ☎</span>
      </div>)
  }
}
