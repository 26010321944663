import React, { Component } from 'react';
import romain from '../assets/romain3.jpg';
import '../css/App.css';

export default class Fees extends Component {
  render() {
    return (
      <div className={"text-box"}>
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li><b>Maître Romain GRAËFFLY fixe ses honoraires en respect de l’article 11 du Règlement Intérieur National de la profession d’Avocat (RIN)</b>, qui dispose que les honoraires de l’avocat prennent en compte les éléments suivants :</li>
          </ul>
        </div>
        <div class={"text-paragraph-justify"}>
          <ul class={"style"}>
            <li>Le temps consacré à l’affaire</li>
            <li>Le travail de recherche</li>
            <li>La nature et la difficulté de l’affaire</li>
            <li>L’importance des intérêts en cause</li>
            <li>Le résultat obtenu</li>
          </ul>
        </div>
        <div>
          <img src={romain} className={"photo"} alt={"romain_graeffly"} />
        </div>
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li><b>Trois formules sont possibles :</b></li>
          </ul>
        </div>
        <h1>Honoraires Forfaitaires</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li>Une rémunération globale est déterminée. Le paiement d’une provision est demandé à l’ouverture du dossier</li>
          </ul>
        </div>
        <h1>
          <span class={"break"}>Honoraires</span>
          <span class={"break"}>au temps passé</span>
        </h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li>Une rémunération est facturée au taux horaire. Le paiement d’une provision est demandé à l’ouverture du dossier</li>
          </ul>
        </div>
        <h1>L'abonnement Mensuel</h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li>Le Client peut bénéficier contre le paiement d’un abonnement mensuel, des services de Maître Romain GRAËFFLY</li>
          </ul>
        </div>
      </div>
    );
  }
}
