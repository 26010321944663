import React, { Component } from 'react';
import romain from '../assets/romain2.jpg';
import '../css/App.css';

export default class Brief extends Component {
  render() {
    return (
      <div className={"text-box"}>
        <h1>
          <span class={"break"}>Un avocat parisien,</span>
          <span class={"break"}>à votre service</span>
        </h1>
        <hr />
        <div class={"text-paragraph-justify"}>
          <ul class={"no-style"}>
            <li>Le Cabinet GRAËFFLY a été créé en août 2008 par Romain GRAËFFLY, Docteur en Droit, ancien attaché d’enseignement et de recherches à l’Université Panthéon-Assas (Paris II), et auteur de nombreux ouvrages et articles réputés.</li>
            <li>Installé depuis janvier 2015 au cœur du quartier Plaisance à PARIS, il intervient dans de multiples secteurs, à destination des particuliers, des petites et moyennes entreprises, des associations, ainsi que des syndicats.</li>
          </ul> 
        </div>
        <div>
          <img src={romain} className={"photo"} alt={"romain_graeffly"} />
        </div>
        <div class={"text-paragraph-justify"}> 
          <ul class={"no-style"}>
            <li>Maître GRAËFFLY a pour principe de répondre à ses Clients dans des délais rapprochés. Pour lui, la disponibilité est une règle placée à égalité avec les seize principes fondamentaux de la profession d’Avocat.</li>
            <li>Il pratique un tarif déterminé en fonction du dossier dès le premier rendez-vous avec le Client.</li>
            <li>Toute prise de premier rendez-vous est facturée à la somme de 100,00 euros.</li>
          </ul> 
        </div>
      </div>
    );
  }
}
