import React from 'react';
import { Switch, Route } from "react-router-dom";
import Plaque from './components/plaque.js';
import Brief from './components/brief.js';
import Skill from './components/skill.js';
import Publish from './components/publish.js';
import Fees from './components/fees.js';
import Contact from './components/contact.js';
import Footer from './components/footer.js';
import SideBar from "./sidebar.js";

import './css/App.css';

const Home = () => (
  <div>
    <Plaque />
    <Brief />
  </div>
);

const Competences = () => (
  <div>
    <Skill />
  </div>
);

const Publications = () => (
  <div>
    <Publish />
  </div>
);

const honoraires = () => (
  <div>
    <Fees />
  </div>
);

const Coordonnees = () => (
  <div>
    <Contact />
  </div>
);

const NotFound = () => (
  <div class={"error-404"}>
    <h1 class={"black"}>La page que vous cherchez n'exite pas.</h1>
  </div>
);

function App() {
  return (
    <div className="App">
      <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />
      <div className="component-box">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/competences" component={Competences} />
          <Route path="/publications" component={Publications} />
          <Route path="/honoraires" component={honoraires} />
          <Route path="/contact" component={Coordonnees} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default App;
